* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "open sans" !important;
}

iframe {
  display: none !important;
  height: 0px !important;
}

p {
  margin-bottom: 0 !important;
}

strong {
  color: #603 !important;
}

a {
  text-decoration: underline !important
}

blockquote {
  margin: 1rem 1rem 1rem !important;
}

@import url('../../blocks/landingpage/src/AboutUs_OurStory.css');
@import url('../../blocks/catalogue/src/OurHotels.css');
@import url('../../blocks/landingpage/src/OurVision.css');
@import url('../../blocks/landingpage/src/AboutUs_OurMission.css');

.border {
  border: 1px solid red;
}

.MuiAppBar-root {
  z-index: 9 !important;
}

.NavBarDiv {
  background-color: #f7f2f5 !important;
  padding: 0px 9.286% 0px 9.286%;
  box-shadow: none !important;
}

.NavBarInnerContainer {
  padding: 12px 0px 9px 0px !important;
  align-items: center;
  justify-content: space-between;
  height: auto;
  min-height: auto;
}

.LogoDiv {
  display: flex;
  align-items: center;
}

.LogoDiv1 {
  display: none !important;
  align-items: center;
}

.RightMenuDiv {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchInput {
  flex: 1;
}

.SearchBar {
  margin-right: 20px !important;
  padding: 6px 6px 6px 20px;
  border-radius: 1.875rem;
  width: 454.63px;
  height: 52px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-size: 14px !important;
}

.SearchIconContainer1 {
  display: flex;
  align-items: center;
}

.SearchIconContainer {
  display: flex;
  align-items: center;
  padding: 12.1px;
  background: #660033;
  border-radius: 50%;
}

.Language_Button_Div {
  font-size: 14px;
  height: fit-content;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 7px 15px;
  cursor: pointer;
  white-space: nowrap;
}

.MoreContainer {
  padding: 0 5px;
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.MoreContainerMobile {
  padding: 0 5px;
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 35px !important;
}

.MoreIcon {
  margin-right: 5px;
  color: #363636;
}

.BookNow {
  height: fit-content !important;
  color: white;
  background: #660033;
  border-radius: 50px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 7px 16px !important;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
}

.hr {
  margin: 7px 1px 0px 1px;
  border-color: #44444478;
}

.MenuIcon950 {
  display: none !important;
  color: #363636;
  font-size: 2rem !important;
  cursor: pointer;
}

.NavBarScondContainer {
  display: flex;
  padding: 0 !important;
  align-items: center;
  justify-content: space-around !important;
}

.NavBarSecondContainerMobile {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  align-items: center;
  justify-content: space-around !important;
}

.TextBox {
  margin: 40px 0 0 0;
}

.ButtonBox {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
}

.ExploreHotel {
  font-size: 14px;
  font-weight: 600;
  color: white;
  background: #660033;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 15px 23px;
  cursor: pointer;
}

.SomethingSurprising {
  margin-top: 10px;
  padding: 0 212px;
  display: flex;
  justify-content: space-between;
}

.MoreText {
  color: #363636;
  font-weight: 500;
  margin: 0;
}

.LanguageText {
  color: #363636;
  font-weight: 700;
  padding: 0 8px;
  margin: 0;
}

.MenuText {
  font-weight: 400;
  color: #363636;
  padding-right: 8px;
}

.MenuText:hover {
  /* / white-space: nowrap; / */
  color: #603;
  cursor: pointer;
}

.HomeStay {
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 4px;
  font-weight: 600;
  font-size: 14px !important;
  color: #660033;
  text-align: center;
  font-style: normal;
  line-height: normal;
}

.HomeRedefine {
  /* letter-spacing: 2px; */
  /* word-spacing: 2px; */
  color: #363636;
  margin-top: 8px;
  font-weight: 600 !important;
  font-size: 2rem;
  text-align: center;
  line-height: 54px;
}

.HomeRedefine1 {
  font-weight: 500 !important;
  line-height: 48px !important;
}

.sliderDiv {
  position: relative;
}

.CarouselHeadImage {
  width: 100%;
  height: 347px;
}

.CarouselHeadDiv {
  padding: 0 0 90px;
}

.carouselImageContainer {
  position: relative;
  width: 571px;
}

.carouselImageContainerShade {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 157px;
  z-index: 1 !important;
  opacity: 0.5;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
}

.CarouselSubDiv {
  background-color: transparent;
  width: 100%;
  position: relative;
}

.CarouselInnerDiv {
  background-color: transparent;
  width: 100%;
  position: absolute;
  bottom: -60px;
  z-index: 2 !important;
  padding: 0 43.5px;
}

.CarouselInnerDivAminities {
  background-color: transparent;
  width: 100%;
  position: absolute;
  bottom: -60px;
  z-index: 2 !important;
  padding: 0 24px;
}

.CarouselInnerContainer {
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 10px 24px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  background-color: white;
}

.CarouselSubTextDiv {
  margin-right: 13px;
}

.CarouselSubImageDiv {
  margin-right: 20px;
  width: 54px;
  height: 54px;
  padding: 5px;
  position: relative;
  border-radius: 50%;
  background-color: #e2e2e2;
}

.CarouselSubImage {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
}

.Explore {
  font-size: 14px;
  font-weight: 600;
  color: white;
  background: #660033;
  border-radius: 50px;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 15px 17px;
  justify-content: center;
  cursor: pointer;
  width: 118px !important;
}

.ExploreSubmit {
  font-size: 14px;
  font-weight: 600;
  color: white;
  background: #660033;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 15px 42px;
  cursor: pointer;
  border-style: none;
  width: 133px !important;
}

.More {
  height: 48px;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  justify-content: center;
  color: white;
  background: #660033;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  border-style: none;
  cursor: pointer;
}

.Explore121 {
  font-size: 12px;
  font-weight: 600;
  width: fit-content;
  color: white;
  background: #660033;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 7px 18px;
  cursor: pointer;
}

.HeadingcrouselText {
  font-weight: 600;
  font-size: 20px;
  color: #363636;
  margin-bottom: 3px;
}

.HeadingcrouselTextc {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 3px;
  color: #363636;
  letter-spacing: -0.7px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 39px;
}

.HeadingcrouselTexujt {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 3px;
  color: #363636;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: normal;
  margin-bottom: 6px;
}

.SomethigInnerDiv {
  width: 40% !important;
  padding: 0 0px;
}

.SomethigInnerDiv1 {
  width: 60% !important;
  padding: 0 40px 0 40px;
}

.SomethigInnerDivHeading {
  color: var(--color-maroon, #603);
  font-size: 14px;
  font-family: Open Sans;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.SomethigInnerDivText {
  font-size: 32px;
  color: #363636;
  font-weight: 500;
  padding-top: 8px;
  margin-bottom: 20px;
}

.TextMoreHotels {
  color: #676766;
  padding-bottom: 25px;
  font-size: 16px;
  width: 455px;
}

.ImageDisplaySelected {
  width: 38.75%;
}

.ImageDisplayCommon {
  position: relative;
  height: 350px;
  cursor: pointer;
}

.ImageDisplayUnSelected {
  width: 16%;
}


.ImageDisplaySelectedBlock {
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  color: white;
  text-align: center;
}

.ImageDisplayUnSelectedHeadText {
  /* -webkit-transform: rotate(-90deg); */
  transform: rotate(-90deg);
  white-space: nowrap;
  text-align: center;
  /* font-family: Open Sans; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.ImageDisplayUnSelectedBlock {
  display: none;
}

.ImageDisplayUnSelectedHeadDiv {
  width: 30%;
  color: white;
  position: absolute;
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.474));
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .ImageDisplayUnSelectedHeadText {
  transform: rotate(-90deg);
  white-space: nowrap;
} */

.OurStoryMainContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 90px;
}

.OurStoryImageDiv {
  width: 61.3%;
}

.OurStoryImage {
  width: 100%;
  aspect-ratio: 5/4.8608;
}

.OurStoryContantDiv {
  background-color: #fff;
  left: 10%;
  position: absolute;
  z-index: 2 !important;
  padding: 70px 58px;
  width: 44%;
  /* height: 486px; */
}

.OurStoryAboutUs {
  font-size: 14px;
  color: #660033;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;
  padding-bottom: 8px !important;
}

.OurStoryExperiences {
  color: #363636;
  margin-bottom: 30px;
  font-weight: 500;
  line-height: 48px;
  font-size: 32px;
  padding-right: 50px;
}

.OurStoryBelieve {
  color: #676766;
  margin-bottom: 30px !important;
  margin-top: 16px !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.ButtonsDiv {
  display: flex;
  margin-bottom: 30px;
}

.ButtonFocused {
  width: fit-content;
  color: #660033;
  background: #e0ccd6;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 7px;
}

.ButtonNotFocused {
  border: 1px solid #c8c8c6 !important;
  width: fit-content;
  color: #676766;
  background: #e9e9e9;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 7px;
}

.ButtonSpace {
  margin-right: 10px;
}

.marginTopTrending {
  margin: 10% 8% 0 !important;
}

.marginTrending {
  margin: 10% 18% 0;
}

.HomeTrendingHotels {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #676766;
  text-align: center;
  padding: 0px 109px;
  margin: 20px 0;
}

.CarouselWithButtonDiv {
  width: 90%;
  max-width: 90%;
}

.CarouselWithButtonPosition {
  position: relative;
  z-index: 1;
  height: 600px;
}

.CarouselArrowMainDiv {
  position: absolute !important;
  z-index: 3;
  bottom: 20px;
  right: 3%;
  display: flex;
}

.CarouselWithButtonIMG {
  position: relative;
  width: 100%;
  height: 100% !important;
  z-index: 1;
}

.CarouselWithImageDiv {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.CarouselWithImageDivShade {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 36.84%;
  z-index: 2 !important;
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
  ;
}

.CarouselArrowDivMargin {
  margin-right: 30px;
}

.CarouselArrowDiv {
  position: relative;
  border: 2px solid #989898 !important;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 8px;
  color: #646464;
}

.CarouselArrowDiv:hover {
  border-color: white !important;
  color: #ffffff !important;
  cursor: pointer;
}


.SubCarouselDiv {
  padding: 14px 0 0 5%;

}

.SubCarouselInnerDiv {
  display: flex;
}

.SubCarouselInnerButtonDiv {
  display: flex;
  justify-content: center;
  padding-top: 72px !important;
}

.SubCarouselHeadText {
  color: #363636;
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 2rem;
  line-height: normal;
}

.SubCarousellocation {
  margin: 14px 0;
  margin-right: 9px;
}

.SubCarousellocationText {
  color: #676766;
  margin: 10px 0;
  font-weight: 400;
  font-size: 1rem;
  text-align: justify;
  line-height: 24px;
}

.AmuniyImage {
  margin-right: 12px;
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
}

.AmunityNumber {
  line-height: 24px;
  font-weight: 600;
  font-size: 18px;
}

.Amunity {
  color: #676766;
  font-weight: 400;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  line-height: 24px
}

.AmunityDiv {
  padding-top: 31px;
  display: grid;
  grid-template-columns: auto auto auto;
}

.AmunityInnerDiv {
  display: flex;
  margin: 0 0 31px;
  justify-content: start;
  padding-left: 12%;
}

.HotelAmenitiesDiv {
  background-image: url("../../blocks/landingpage/assets/rectangle109.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 148px;
  padding: 148px 8.4% 243px;
}

.HomeTrendingHotelsPadding {
  padding: 0 12%;
}

/* .border {
  border: 1px solid red;
} */
/* / 22 June / */

.CarouselSubImageDiv1 {
  /* height: 400px; */
  margin-right: 5px;
  padding: 2.5px;
  width: 27px;
  height: 29px;
  display: flex;
  justify-content: center;
}

.CarouselArrowMainDiv1 {
  position: absolute;
  z-index: 3;
  bottom: 45%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}

.CarouselArrowDiv1 {
  border: 2px solid #ffffff !important;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px 4px;
  color: #ffffff;
  cursor: pointer;
}

.SubcrouselTextc {
  color: #646464;
  font-size: 0.9rem;
}

.SubcrouselText {
  color: #676766;
  width: 100%;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
  height: 48px;
}

.SubcrouselTexujt {
  color: #676766;
  width: 100%;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 21px;
}

.CarouselHeadImage1 {
  width: 100%;
  height: 450px;
}

.contactUs {
  display: flex;
  flex-direction: row;
}

.contactUs1 {
  display: flex;
  flex-direction: row;
  padding: 5rem 8.44rem;
  background: #F6F6F6;
}

.contactUsImageDiv {
  width: 49.7192%;
  background-image: url(/static/media/rectangle41.3f285997.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  height: 816px;
}

.contactUsDiv {
  width: 50%;
  padding: 8% 5% 6.9% 5%;
}

.contactUsAllin {
  text-align: left !important;
  margin-bottom: 15px !important;
}

/* ///// inputs /////*/
.formDiv {
  margin-top: 50px;
}

.contFrm {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inptFld,
.tel-inptFld {
  width: 47%;
  height: 48px;
  border: 0;
  padding: 0 10px 0 20px;
  margin: 0 0 20px;
  font-size: 14px;
  color: #676766;
  font-family: "Inter", sans-serif !important;
  background-color: #f6f6f6;
}

.tel-inptFld {
  width: 47% !important;
  padding: 0;
}

.contact-input {
  border: none !important;
  background-color: #f6f6f6 !important;
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
  color: #676766 !important;
  width: 100% !important;
  height: 100% !important;
  padding-left: 20px !important;

}

.textarea {
  width: 100%;
  height: 100px;
  border: 0;
  padding: 10px 10px 0 20px !important;
  margin: 0 0 20px;
  padding: 0;
  font-size: 14px;
  color: #000;
  resize: none;
  background-color: #f6f6f6;
}

.contactUsTextDiv {
  display: flex;
  align-items: end;
  height: 238px;
  position: absolute;
  z-index: 3;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 0 10% 5%;
  color: white;
  opacity: 0.6;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
}

.Explore1 {
  margin-top: 10px;
  width: fit-content;
  color: rgb(255, 255, 255);
  background: #ffffff33;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  cursor: pointer;
}

.ReadMoreButton {
  font-size: 14px;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  background: #660033;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 15px 32px;
  cursor: pointer;
}

.hello {
  width: 26px;
  height: 26px;
  margin-bottom: 10px;
}

.first-child {
  margin-bottom: 10px !important;
  color: white !important;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Second-child {
  margin-bottom: 10px !important;
  color: white !important;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.FooterBackGroungDiv {
  background-color: #f8efe0;
  padding: 20px 5%;
  position: relative;
}

.Contour_bg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.07 !important;
  width: 100%;
  height: 100%;
}

.FooterImageDiv {
  margin: -30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FooterOpacity {
  opacity: 0.8 !important;
}

.FooterPaddingDiv {
  padding: 6% 0;
}

.hr1 {
  border-color: #88888878;
  border-bottom: none;
}

.Copyright {
  padding-top: 20px;
  color: var(--color-black, #363636);
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

:root {
  --AboutUs-width: 230px;
  --AboutUs-background: white;
}

/* / AboutUs styles / */
.AboutUs {
  position: relative;
}

.AboutUsul {
  position: absolute;
  margin: 19px 0 0 0;
  padding: 3px 5px;
  width: 230px;
  width: var(--AboutUs-width);
  left: 49%;
  margin-left: calc((230px / 2.1) * -1);
  margin-left: calc((var(--AboutUs-width) / 2.1) * -1);
  box-sizing: border-box;
  z-index: 1000 !important;
  background: white;
  background: var(--AboutUs-background);
  border-radius: 5px;
  list-style: none;
  color: black;
}

.AboutUsdiv {
  width: 218px;
  height: 46px;
  background: #ffffff;
  display: flex;
  justify-content: left;
  align-items: center;
  border-left: 3px solid transparent !important;
  padding-left: 20px;
  cursor: pointer;
}

.AboutUsSVG {
  fill: #646464;
  width: 18px;
  height: 18px;
}

.AboutUsSubMenu {
  color: var(--color-grey, #646464);
  font-size: 14px;
  font-weight: 400;
  padding-left: 8px;
}

.AboutUsdiv:hover {
  border-left: 3px solid #660033 !important;
  background: #f6f6f6;
}

.AboutUsdiv:hover .AboutUsSVG {
  fill: #363636;
}

.AboutUsdiv:hover .AboutUsSubMenu {
  color: #363636;
  font-weight: 500;
}

/* / AboutUs triangle / */
.AboutUsul::before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  top: -9px;
  left: 50%;
  margin-left: -10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
}

/* / Close the AboutUs with outside clicks / */
.AboutUs-close ul {
  display: none !important;
}

.modal2 {
  position: fixed;
  z-index: 990 !important;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.57);
  display: flex;
}

.modal1 {
  display: none;
}


/* / Media  / */
@media(max-width:1308px) {
  .BookNow {
    width: 103px;
    margin-left: 6px;
  }
}

@media (min-width: 600px) {
  .HomeTrendingHotels {
    width: 100%;
    word-break: break-all;
    padding-left: 60px;
    padding-right: 20px;
  }

  .CarouselWithButtonText {
    font-size: 50px !important;
  }
}

@media (max-width: 500px) {
  .TextMoreHotels {
    width: 285px;
    word-break: break-all;
  }

  .HomeTrendingHotels {
    word-break: break-all;
    padding-left: 15px;
    padding-right: 15px;
  }

  .CarouselWithButtonText {
    font-size: 40px !important;
    line-height: 55px !important;
  }

  .SubCarouselDiv {
    /* padding:0px !important; */
  }

  .AmuniyImage {
    margin-right: 10px !important;
    width: 25px !important;
    height: 25px !important;
  }

  .Amunity {
    line-height: 15px !important;
  }

  .AmunityNumber {
    font-size: 16px !important;
  }

  .AmunityInnerDiv {
    padding-left: 0px !important;
  }
}

@media (max-width: 320px) {
  .TextMoreHotels {
    width: 200px;
    word-break: break-all;
  }
}

@media(max-width:1279px) {

  .BookNow {
    width: 125px;
    padding: 7px 18px !important;
    margin-left: 8px;
  }
}

@media (max-width: 1200px) {
  :root {
    --AboutUs-width: 200px;
  }

  .HomeTrendingHotelsPadding {
    padding: 0 5%;
  }

  .BookNow {
    width: 115px;
    padding: 7px 13px !important;
    margin-left: 8px;
  }

  .AboutUsdiv {
    width: 100%;
    padding-left: 10px;
  }

  .AboutUsul {
    margin-left: calc((var(--AboutUs-width) / 2.5) * -1);
  }

  .AmunityInnerDiv {
    display: flex;
    margin: 5% 0;
  }

  .OurStoryExperiences {
    font-weight: 500;
    font-size: 1.8rem;
  }

  .OurStoryImageDiv {
    width: 80%;
  }

  .OurStoryContantDiv {
    left: 6%;
    padding: 30px 20px;
    width: 65%;
  }

  .MenuText {
    font-size: 0.9rem;
    margin-right: -4px;
  }

  .NavBarDiv {
    padding: 1% 8%;
  }

  .SomethingSurprising {
    margin-top: 4%;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1100px) {
  .BookNow {
    width: 130px;
    padding: 7px 12px !important;
    margin-left: 8px;
  }
}

@media (max-width: 1000px) {
  .AboutUsul {
    margin-left: calc((var(--AboutUs-width) / 3.3) * -1);
  }

  .BookNow {
    width: 125px;
    padding: 7px 12px !important;
    margin-left: 8px;
  }

  .AboutUsul::before {
    margin-left: -20px;
  }

  .CarouselWithButtonIMG {
    height: 100%;
  }

  .contactUsImageDiv {
    width: 40%;
  }

  .contactUsDiv {
    width: 60%;
  }

  .CarouselWithButtonText {
    position: absolute;
    z-index: 3;
    font-size: 5vw;
    left: 5%;
    bottom: 0;
  }

  .CarouselWithButtonDiv {
    width: 100%;
    max-width: 100%;
  }

  .HomeTrendingHotelsPadding {
    padding: 0 2%;
  }

  .marginTopTrending {
    margin: 128px 2% 0 !important;
  }

  .NavBarDiv {
    padding: 1% 2%;
  }

  .ImageDisplayCommon {
    height: 250px;
  }
}

@media (max-width: 950px) {
  .FooterPaddingDiv {
    padding: 45px 0;
  }

  .NavBarScondContainer {
    display: none !important;
  }

  .MuiToolbar-regular {
    min-height: 0px !important;
  }

  .MenuIcon950 {
    display: block !important;
  }

  .RightMenuDiv {
    width: 78%;
  }

  .SearchBar {
    padding: 2px 7px;
    width: 100%;
  }

  .BookNow {
    font-size: 0.9rem;
    border-radius: 50px;
    padding: 0 15px;
  }

  .NavBarDiv {
    padding: 1% 2%;
  }

  .IrisLogo {
    width: 100px;
  }

  .SomethingSurprising {
    padding: 0 2%;
    display: block;
  }

  .SomethigInnerDiv {
    width: 100% !important;
    padding: 0 20px 50px;
  }
}

@media (max-width: 900px) {

  .HotelAmenitiesDiv {

    padding: 9% 3%;
  }

  .SubCarouselDivChild {
    margin: 0px 25px !important;
    padding: 0px !important;
  }

  .SubCarouselDiv-InnerParent {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px !important;
  }

  .OurStoryMainContainer {
    position: relative;
    width: 100%;
    display: block;
  }

  .OurStoryImageDiv {
    width: 100%;
  }

  .OurStoryContantDiv {
    position: relative;
    left: 0;
    padding: 30px 20px;
    width: 100%;
  }

  .contactUsImageDiv {
    display: none;
  }

  .contactUsDiv {
    width: 100%;
  }
}

@media (max-width: 750px) {
  .ImageDisplayCommon {
    height: 200px;
  }

  .RightMenuDiv {
    width: 77%;
  }

  .LogoDiv1 {
    display: flex !important;
    z-index: 999999;
    visibility: collapse;
  }

  .MoreContainer {
    display: flex !important;
  }

  .SearchBar {
    padding: 2px 5px;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .HotelAmenitiesDiv {
    margin-top: 0px;
    padding: 9% 12%;
  }

  .SubCarouselDivChild {
    margin: 0px 25px !important;
    padding: 0px !important;
  }

  .SubCarouselDiv-InnerParent {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px !important;
  }

  .CarouselHeadImage1 {
    width: 100%;
    height: 420px;
  }

  .HomeRedefine {
    /* letter-spacing: 1px; */
    word-spacing: 1px;
    font-size: 1.5rem;
  }

  .SubcrouselText {
    font-size: 0.75rem;
  }

  .HeadingcrouselText {
    font-weight: 500;
    font-size: 0.9rem;
  }

  .Explore121 {
    font-size: 0.8rem;
    padding: 8px 15px;
  }

  .ButtonsDiv {
    display: block;
    margin-bottom: 30px;
  }

  .ButtonFocused {
    width: 200px;
    margin-bottom: 10px;
  }

  .ButtonNotFocused {
    width: 200px;
    margin-bottom: 10px;
  }

  .OurStoryExperiences {
    font-weight: 500;
    font-size: 1.4rem;
  }
}

@media (max-width: 500px) {
  .HotelAmenitiesDiv {
    margin-top: 0px;
    padding: 9% 8%;
  }

  .SubCarouselDivChild {
    margin: 0px 25px !important;
    padding: 0px !important;
  }

  .SubCarouselDiv-InnerParent {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px !important;
  }

  .CarouselHeadImage1 {
    width: 100%;
    height: 400px;
  }

  .inptFld {
    width: 100%;
  }

  .tel-inptFld {
    width: 100%;
  }

  .CarouselArrowDiv {
    border: 2px solid #989898 !important;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 14px 8px;
    color: #646464;
  }

  .HomeTrendingHotelsPadding {
    font-size: 0.7rem;
    padding: 0 0;
  }

  .marginTopTrending {
    margin: 10% 1% 0 !important;
  }

  .InputBaseFont {
    color: transparent !important;
    padding: 0 !important;
  }

  .HomeRedefine {
    font-size: 1.2rem;
  }

  .SearchBar {
    padding: 0px 5px;
    width: 40%;
  }

  .IrisLogo {
    width: 80px;
  }

  .BookNow {
    font-size: 0.7rem;
  }

  .SearchIconContainer {
    padding: 6px;
  }

  .Search {
    width: 16px;
    height: 16px;
  }

  img.Search1 {
    width: 18px !important;
    height: 18px !important;
  }

  .SearchBar {
    padding: 0px 5px !important;
  }
}

@media (max-width: 450px) {
  .Explore121 {
    display: none;
  }

  .CarouselSubImageDiv {
    display: none;
  }

  .HotelAmenitiesDiv {
    margin-top: 0px;
    padding: 9% 5%;
  }

  .SubCarouselDiv {
    padding: 0px;
  }
}

@media (max-width: 350px) {
  .HotelAmenitiesDiv {

    padding: 9% 1%;
  }

  .IrisLogo {
    width: 70px;
  }

  .BookNow {
    font-size: 0.6rem;
  }

  .SearchIconContainer {
    padding: 5px;
  }

  /* .MuiInputBase-input {
    padding: 4px 0 !important;
  } */

  .HomeStay {
    letter-spacing: 2px;
  }

  .HomeRedefine {
    font-size: 0.9rem;
  }

  .CarouselSubDiv {
    display: none;
  }
}

.slick-slider {
  height: 600px;
}

.Carousel1 {
  height: 470px;
  overflow: hidden;
}

.Aminities1 {
  height: 500px;
  position: relative;
}

.switch-button {
  background-color: #603 !important;
}

.paper-position {
  top: 169px !important
}

.aboutUsabc {
  text-decoration: none;
}

.menulist-item:hover {
  border-left: 3px solid #603;
}

.CarouselWithButtonText {
  position: absolute;
  z-index: 3;
  font-size: 62px;
  line-height: 60px;
  font-weight: 800;
  text-transform: uppercase;
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.22) 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  bottom: 0;
  left: 10%;
  letter-spacing: 2px !important;
  -webkit-text-fill-color: transparent;
}

.CarouselWithButtonText pre {
  margin-bottom: 0;
  overflow: hidden;
}

.footerlogoslo1 {
  position: relative;
  bottom: 0px;
  width: 133px;
  left: 0;
}

.footerlogoslogn {
  position: absolute;
  bottom: 85px;
  /* width: 514px; */
}

.contentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

@media (max-width: 1250px) {

  .footerlogoslo1 {
    position: relative;
    bottom: 0px;
    width: 133px;
    left: 0;
  }

  .footerlogoslogn {
    position: absolute;
    bottom: 85px;
    /* width: 514px; */
  }
}

@media (min-width: 950px) {
  .MoreContainerMobile {
    padding: 0 5px;
    width: fit-content;
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 35px !important;
  }
}

@media (width: 950px) {
  .MoreContainer {
    display: none !important;
  }

  .MoreContainerMobile {
    padding: 0 5px;
    width: fit-content;
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

@media (min-width: 951px) {
  .MoreContainer {
    display: flex;
  }

  .MoreContainerMobile {
    display: none;
  }
}


@media (max-width: 951px) {

  .footerlogoslo1 {
    position: relative;
    bottom: 0px;
    width: 112px;
    left: 0;
  }

  .footerlogoslogn {
    position: absolute;
    bottom: 85px;
    /* width: 450px; */
  }
}

@media (max-width: 600px) {

  .footerlogoslo1 {
    position: relative;
    bottom: 0px;
    width: 100px;
    left: 0;
  }

  .footerlogoslogn {
    position: absolute;
    bottom: 85px;
    /* width: 400px; */
  }
  .NavBarDiv {
    padding: 1rem 1.1rem !important;
  }

  .NavBarInnerContainer {
    display: flex;
    flex-direction: column;
  }

  .RightMenuDiv {
    flex-wrap: wrap;
    width: 100%;
  }

  .SearchBar {
    width: 100%;
  }

  .searchInput {
    margin-top: 3%;
    width: 100%;
  }

  .contentContainer {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.backdrop-root {
  z-index: 1 !important;
}

.menuColor {
  color: #646464;
  font-size: 0.875rem;
}

.menuColor:hover {
  color: #363636 !important;
  font-weight: 500;
  font-size: 0.875rem;
}

.down-arrow {
  margin-top: 1px;
}

.SubCarousellocationTextDiv {
  height: 87px;
  overflow: auto;
  word-wrap: break-word;
}

/* for landing page carousel */
.ImageDisplayMainDiv .slick-active {
  width: 4.58% !important;
}

.ImageDisplayMainDiv .slick-current {
  width: 30% !important;
}

.ImageDisplayMainDiv {
  width: 100%;
  position: relative;
}

.ImageDisplayMainDiv .slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 350px !important;
}

.ImageDisplay {
  width: 100%;
  height: 100%;
}

.ImageDisplaySelectedBlockFirst {
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 12px !important;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ImageDisplaySelectedBlockSecond {
  margin-bottom: 30px !important;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
  width: max-content;
  margin-inline: auto;
}

.ImageDisplayMainDiv .slick-slide .ImageDisplayUnSelectedBlock {
  display: none !important;
}

.ImageDisplayMainDiv .slick-current .ImageDisplayUnSelectedBlock {
  width: 100%;
  display: flex !important;
  justify-content: center;
  position: absolute;
  bottom: 0;
  color: white;
  text-align: center;
}

.ImageDisplayMainDiv .slick-slide .ImageDisplayUnSelectedHeadDivNew {
  width: 30%;
  color: white;
  position: absolute;
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.474));
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.ImageDisplayMainDiv .slick-current .ImageDisplayUnSelectedHeadDivNew {
  display: none !important;
}

.roundarrow {
  position: absolute;
  z-index: 9;
  top: 40%;
  right: 20%;
  cursor: pointer;
}

.promotion-carousal .slick-track {
  display: flex;
  grid-column-gap: 20px;
}

.promotion-carousal .slick-slide {
  width: 571px !important;
}

@media (max-width: 710px) {
  .NavBarDiv {
    padding: 1rem 1.1rem !important;
  }

  .NavBarInnerContainer {
    display: flex;
    flex-direction: column;
  }

  .RightMenuDiv {
    flex-wrap: wrap;
    width: 100%;
  }

  .SearchBar {
    width: 100%;
  }

  .searchInput {
    margin-top: 3%;
    width: 100%;
  }

  .contentContainer {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 480px) {
  .NavBarDiv {
    padding: 1rem 1.1rem !important;
  }

  .NavBarInnerContainer {
    display: flex;
    flex-direction: column;
  }

  .RightMenuDiv {
    flex-wrap: wrap;
    width: 100%;
  }

  .SearchBar {
    width: 100%;
  }

  .searchInput {
    margin-top: 3%;
    width: 100%;
  }

  .contentContainer {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 360px) {
  .RightMenuDiv {
    flex-wrap: wrap;
    width: 100%;
  }

  .searchInput {
    margin-top: 3%;
    width: 100%;
  }

  .contentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}