.custom-popover-list-item:hover {
    border-left: 3px solid #603;
}

.custom-popover-text:hover {
    color: #363636;
    font-weight: 500;
    line-height: 1.25rem;
}

.custom-popover-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    color: #646464;
}