.OurMissionLeft {
    width: 40%;
    padding: 5% 2% 2% 5%;
  }
  .OurMissionRight {
    display: flex;
    justify-content: space-around;
    width: 60%;
  }
  .OurMissionLeftDiv {
    width: 45%;
  }
  .MissionImg {
    width: 100%;
    height: 100%;
  }
  .OurMissionPadd {
    padding: 8% 5%;
  }
  .MissionBelievePadd {
    padding: 0 12% 10%;
  }
  .OurBlieve {
    background-color: #f6f6f6;
  }
  .OurMissionBack {
    background-color: transparent !important;
    position: relative;
    top: -160px;
  }
  .Mission {
    position: relative;
  }
  .OurMissionDiv {
    padding: 0 16% 5% !important;
    margin-top: -80px;
  }
  @media (max-width: 1100px) {
    .OurMissionRight {
      width: 50%;
    }
    .OurMissionLeft {
      width: 50%;
      padding: 2% 2% 2% 5%;
    }
    .OurMissionPadd {
      padding: 8% 1%;
    }
    .MissionBelievePadd {
      padding: 0 0 15%;
    }
  }
  @media (max-width: 1000px) {
    .OurMissionRight {
      width: 100%;
    }
    .OurMissionLeft {
      width: 100%;
      padding: 5% 2%;
    }
    .OurMissionLeftDiv {
      width: 40%;
    }
    .MissionImg {
      width: 100%;
      height: auto !important;
    }
    .MissionBelievePadd {
      padding: 0 0 0;
    }
    .OurMissionBack {
      background-color: transparent !important;
      position: relative;
      top: 0;
    }
    .OurMissionDiv {
      padding: 0 1% 5% !important;
      margin-top: 30px;
    }
  }
  