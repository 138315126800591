.OurHotelDiv1 {
    position: relative;
    width: 100%;
    height: calc(100vw / 3.66);
    margin-bottom: 0;
  }
  .OurHotelImage1 {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .OurHotelInnerDiv {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      #fff 0%,
      rgba(255, 255, 255, 0.337) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 60px 22%;
  }
  .OurHotelWorld {
    color: #000;
    margin-top: 12px;
    font-weight: 500 !important;
    font-size: 32px;
    text-align: center;
  }
  .OurHotelCarousel {
    margin-top: 80px;
    margin-bottom: 7rem;
  }
  .OurHotelCaroselMain {
    padding: 0 9.286%;
    display: flex;
    width: 100%;
  }
  .OurHotelCaroselDiv {
    width: 50%;
    max-width: 50%;
    height: max-content;
  }
  .OurHotelInfoDiv {
    width: 50%;
    max-width: 50%;
    padding: 4.5% 1% 0 3%;
  }
  .Thumbnail{
    overflow: hidden;
    height: max-content;
  }
  .OurHotelThumbnail {
    margin-top: 5px;
    cursor: pointer;
    padding: 2%;
    border: 1px solid transparent;
    height: 100%;
  }
  
  .OurHotelThumbnailImg {
    width: 100%;
    height: 100%;
  }
  
  .slick-slide.slick-active.slick-current .OurHotelThumbnail {
    /* / border: 1px solid #660033; / */
    border-color: #660033;
  }
  .OurHotelsExplore {
    font-size: 14px;
    font-weight: 600;
    width: 143px;
    height: 48px;
    color: white;
    background-color: #660033;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .RoomsTariffsText {
    color: #676766;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    padding-bottom: 15px;
  }
  .OurHotelBtnFocus {
    margin-right: 10px;
    padding: 10px 15px;
    background-color: #f0e5eb;
    color: #660033;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  .OurHotelBtnNotFocus {
    margin-right: 10px;
    color: #646464;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .OurHotelBtnDiv {
    display: flex;
    padding-bottom: 30px;
  }
  .OurHotelDetailsHead {
    color: #363636;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
  .IrisHide {
    display: none;
  }
  .IrisShow1 {
    display: block;
  }
  .IrisShow2 {
    display: flex;
  }
  .Roomsmargin {
    padding-bottom: 20px;
  }
  .AmenitiesDiv {
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .AmenitiesCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: linear-gradient(180deg, #ececec 0%, rgba(217, 217, 217, 0) 100%);
  }
  .AmenitiesName {
    text-align: center;
    color: #363636;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 3px;
  }
  .AmenitiesContainer {
    display: flex;
    justify-content: center;
  }
  .AmenitiesImg {
    width: 40px;
    height: 40px;
  }
  .AmenitiesDivWidth {
    width: 105px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .OurHotelContact {
    width: 337px;
    height: 80px;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.04);
    background-color: white;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
  }
  .OurHotelContactImg {
    margin-right: 15px;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }
  .OurHotelContactHead {
    color: #646464;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
  .OurHotelContactText {
    color: #363636;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }
  .OurHotelCarouselPadd {
    margin-bottom: 80px;
  }
  .slick-slider {
    height: auto !important;
}
.OurHotelCaroImg {
  width: 100% !important;
  height: 100% !important;
}
.OurHotelRelative{
  position: relative;
}
  @media (max-width: 1200px) {
    .OurHotelInnerDiv {
      padding: 6% 10%;
    }
    .OurHotelCaroselMain {
      padding: 0 8%;
    }
  }
  @media (max-width: 1000px) {
    .OurHotelCaroselMain {
      display: block;
      padding: 0 2%;
    }
    .OurHotelCaroselDiv {
      width: 100%;
      max-width: 100%;
    }
    
    .OurHotelInfoDiv {
      width: 100%;
      max-width: 100%;
      padding: 4.5% 1% 0 3%;
      margin-top: 12%;
    }
    .OurHotelInnerDiv {
      padding: 6% 5%;
    }
    .OurHotelWorld {
      font-size: 20px;
    }
  }
  @media (max-width: 650px) {
    .OurHotelInnerDiv {
      padding: 6% 2%;
    }
    .OurHotelWorld {
      font-size: 16px;
      margin: 0;
    }
    .OurHotelImage1 {
      height: 120%;
    }
  }
  @media (max-width: 450px) {
    .OurHotelInnerDiv {
      padding: 6% 1%;
    }
    .OurHotelWorld {
      font-size: 10px;
    }
    .OurHotelBtnDiv {
      display: block;
    }
    .OurHotelBtnFocus {
      margin: 10px 0;
    }
    .OurHotelBtnNotFocus {
      margin: 10px 0;
    }
    .OurHotelContact {
      width: 100%;
    }
    .OurHotelImage1 {
      height: 150%;
    }
  }
  
.Thumbnail .slick-track {
  width: 100% !important;
  display: flex;
}
.Thumbnail .slick-slide {
  height: 6.6875rem !important;
  width: 25% !important;
}

.screenPhoto .slick-slide {
  height: 25.875rem !important;
}

.slick-slide{
  height: unset !important;
}