.border {
  border: 1px solid red;
}

.OurVisionDiv {
  padding: 70px 10%;
}

.OurVisionTxt {
  color: #363636;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 38.4px;
}

.VisionImg1 {
  margin-bottom: 15%;
  width: 100% !important;
}

.VisionImg2 {
  margin-top: 15%;
  width: 100% !important;
}

.VisionImgDiv {
  justify-content: space-between;
}

.VisionImg {
  padding-right: 50px;
  padding-bottom: 40px;
  width: 408px;
  height: 398px;
}

.VisionImg11{
 padding-top: 40px;
 padding-right: 50px;
 width: 408px;
 height: 398px;
}

.OurGoal {
  padding: 5%;
  display: flex;
}

.OurGoalLeft {
  width: 50%;
  display: flex;
  justify-content: right;
}

.GoalImg {
  width: 100%;
  padding: 0 3%;
}

.GoalImgMaggin {
  margin-top: 5vw;
}

.OurGoalRight {
  width: 50%;
  padding: 5.5% 2% 0 5%;
}

.OurGoalTxt {
  text-align: left !important;
}

.OurGoalRightTxt {
  color: #676766;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;
  padding: 30px 0 10px;
}

.OperateDiv {
  padding: 5% 19% !important;
}

.VisionValuePadd {
  padding: 0 10%;
}

.VisionValue {
  margin-top: -5px;
  padding: 9% 21%;
  position: relative;
}

.VisionValueTxt {
  color: #676766;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-top: 40px;
}

.Carousel_Height_Vision .slick-track .slick-slide {
  height: fit-content !important;
}

@media (max-width: 1100px) {
  .OperateDiv {
    padding: 70px 12% !important;
  }

  .OurGoalRight {
    padding: 5.5% 1% 0 2%;
  }

  .OurGoal {
    padding: 1%;
  }
}

@media (max-width: 1000px) {
  .VisionValue {
    padding: 6% 1%;
  }

  .OperateDiv {
    padding: 70px 5% !important;
  }

  .OurGoal {
    display: block;
  }

  .OurGoalLeft {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .OurGoalLeftDiv {
    width: 30%;
  }

  .GoalImg {
    width: 100%;
    padding: 0 3%;
  }

  .GoalImgMaggin {
    margin-top: 0vw;
  }

  .OurGoalRight {
    width: 100%;
    padding: 5.5% 2% 0 5%;
  }

  .OurVisionDiv {
    padding: 70px 5%;
  }

  .OurVisionTxt {
    font-size: 20px;
    line-height: normal;
  }
}

@media (max-width: 700px) {
  .VisionValuePadd {
    padding: 0;
  }

  .OperateDiv {
    padding: 70px 1% !important;
  }

  .OurVisionDiv {
    padding: 70px 1%;
  }

  .OurGoalRight {
    width: 100%;
    padding: 5.5% 1% 0;
  }

  .OurGoalRightTxt {
    font-size: 14px;
    padding: 10px 0 10px;
  }

  .OurVisionTxt {
    font-size: 14px;
  }
}