.OurHotelDiv1 {
  position: relative;
  height: calc(100vw / 3.66);
  margin-bottom: 0;
  background-image: url(../assets/about_image_image.png);
  width: 100vw;
  background-repeat: no-repeat;
  background-size: contain;
}

.OurHotelImage1 {
  position: relative;
  width: 100%;
  height: 100%;
}

.OurMissionDiv1 {
  position: relative;
  width: 100%;
  height: calc(100vw / 3.2);
  margin-bottom: 140px;
}
.OurMissionImage1 {
  position: relative;
  width: 100%;
  height: 100%;
}
.OurmissionLastdiv {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 122px;
  width: 36%;
  height: 100%;
  color: var(--color-white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 5% 5%;
  background: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(12px);
}

.OurHotelInnerDiv {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    #fff 0%,
    rgba(255, 255, 255, 0.337) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 5% 25%;
}
.AboutOurStoryDiv {
    display: flex;
    padding: 80px 0;
  }
  .AboutOurStoryContent {
    width: 50%;
    padding: 60px 20px 60px 50px;
  }
  .AboutOurStoryImage {
    width: 50%;
    display: flex;
    justify-content: right;
  }
  .StoryImage {
    width: 95%;
    height: 100%;
  }
  .AboutOurStoryHead {
    color: #000;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
  }
  .AboutOurStoryTxt {
    margin-top: 30px;
    color: #676766;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .OurCoreValues {
    background-color: #f6f6f6;
    padding: 70px 0;
  }
  .OurCorePad {
    padding: 0 20%;
  }
  .OurCoreContainerLeft {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    padding-right: 10px;
    align-content: flex-start;
  }
  .OurCoreContainerRight {
    width: 50%;
    padding-top: 60px;
    padding-left: 10px;
  }
  .OurCoreDiv {
    width: 100%;
    display: flex;
    padding: 0px 10% 0;
  }
  .OurValuesDiv {
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    width: 470px;
    height: 265px;
    flex-shrink: 0;
    border-radius: 5px;
    background-color: white;
    padding: 45px 60px;
  }
  .OurValuesHead {
    color: #363636;
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
  }
  .OurValuesTxt {
    color: #676766;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-top: 20px;
  }
  .OurCoreCircle {
    width: 89px;
    height: 89px;
    border-radius: 89px;
    opacity: 0.10000000149011612;
    background-color: #660033;
    position: absolute;
    left: -44px;
    top: -44px;
  }

  .OurValuesCarousal {
    padding: 50px 0;
    background-color: white;
  }

  .valuesCarouselDiv {
    padding: 0 2%;
  }
  
  .valuesCarouselImage {
    height: 27vw;
    width: 100%;
  }
  .OurStoryNew {
    position: relative;
    padding: 0 5% !important;
  }
  .StoryImg {
    width: 100%;
    height: 100%;
  }
  .OurStoryNewTxtDiv {
    width: 100%;
    position: relative;
    background-color: transparent;
    padding: 0 7%;
    bottom: 100px;
  }
  .OurStoryNewTxt {
    box-shadow: 0px 12px 34px 0px rgba(0, 0, 0, 0.07) !important;
    background-color: white;
    padding: 1% 7% 4%;
  }
  .AboutOurStoryAllin {
    text-align: center !important;
    margin-top: 30px;
    color: #676766;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .NewProject {
    width: 100%;
  }
  .NewProject1 {
    width: 100%;
    margin-top: 31px;
  }
  .Project {
    padding-bottom: 80px;
    position: relative;
  }
  .OurHotelWorld {
    color: #000000;
    margin-top: 20px;
    font-weight: 500 !important;
    font-size: 32px !important;
    text-align: center;
  }
  @media (max-width: 1200px) {
    .AboutOurStoryContent {
      padding: 40px 20px 40px 30px;
    }
    .OurCoreDiv {
      padding: 0px;
    }
  }
  @media (max-width: 1100px) {
    .OurHotelInnerDiv {
      padding: 5% 10%;
    }
    
  }
  @media (max-width: 1000px) {
    .OurHotelInnerDiv {
      padding: 5% 5%;
    }
    .OurHotelWorld {
      font-size: 20px;
    }
    .AboutOurStoryAllin {
      text-align: justify !important;
    }
    .OurStoryNew {
      padding: 0 !important;
    }
    .OurStoryNewTxtDiv {
      padding: 0;
      bottom: 0px;
    }
    .OurStoryNewTxt {
      padding: 1% 3% 4%;
      margin-bottom: 50px;
    }
    .OurCoreValues {
      padding: 50px 0;
    }
    .OurCorePad {
      padding: 0 5%;
    }
    .OurCoreContainerLeft {
      padding-right: 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .OurCoreContainerRight {
      width: 100%;
      padding-top: 0px;
      padding-left: 0px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .OurCoreDiv {
      display: block;
      padding: 10px 5%;
    }
  
    .AboutOurStoryDiv {
      display: block;
      padding: 60px 3%;
    }
    .AboutOurStoryContent {
      width: 100%;
      padding: 0 0 40px;
    }
    .AboutOurStoryImage {
      width: 100%;
      display: block;
    }
    .StoryImage {
      width: 100%;
      height: 90%;
    }
  }
  @media(min-width: 650px){
    .OurHotelWorld {
      font-size: 16px;
      margin: 0;
    }
    .OurHotelInnerDiv {
      padding: 5% 2%;
    }
  }
  @media (max-width: 600px) {

    .OurCorePad {
      padding: 0 1%;
    }
    .OurCoreContainerLeft {
      display: block;
    }
    .OurCoreContainerRight {
      display: block;
    }
    .OurCoreDiv {
      display: block;
      padding: 10px 1%;
    }
    .OurValuesDiv {
      width: 100%;
      height: fit-content;
      padding: 4%;
    }
    .OurCoreCircle {
      display: none;
    }
  }
  
  @media (max-width: 500px) {
    .AboutOurStoryAllin {
      font-size: 10px;
      line-height: normal;
    }
    .OurValuesHead {
      font-size: 18px;
      line-height: normal;
    }
  
    .OurValuesTxt {
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
      padding-top: 10px;
    }
    .AboutOurStoryHead {
      font-size: 20px;
      line-height: normal;
    }
    .AboutOurStoryTxt {
      font-size: 12px;
      line-height: normal;
    }
  }
  @media(min-width:450px){
    .OurHotelWorld {
      font-size: 10px !important;
    }
    .OurHotelInnerDiv {
      padding: 5% 1%;
    }
    .OurmissionLastPtag{
      font-size: 8px;
    }
    .OurmissionLastdiv{
      width: 40%;
      line-height: 12px;
      padding: 3%;
    }
  }
  